import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import axios from 'axios';
import config from '../config';

const PackageForm = ({ pkg, onSave, onCancel, auth }) => {
  const [url, setURL] = useState(pkg ? pkg.url : '');
  const [name, setName] = useState(pkg ? pkg.name : '');
  const [hash, setHash] = useState(pkg ? pkg.hash : '');
  const [version, setVersion] = useState(pkg ? pkg.version : '');
  const [onlyCheckExists, setOnlyCheckExists] = useState(pkg ? pkg.onlyCheckExists : false);
  const [mustNotExist, setMustNotExist] = useState(pkg ? pkg.mustNotExist: false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { url, name, hash, version, onlyCheckExists, mustNotExist };
      if (pkg) {
        await axios.put(`${config.API_BASE_URL}/package/${pkg.id}`, data, {
          auth: auth,
        });
      } else {
        await axios.post(`${config.API_BASE_URL}/package`, data, {
          auth: auth,
        });
      }
      onSave();
    } catch (error) {
      const errorMessage = `HTTP Status: ${error.response.status}, Error Code: ${error.response.data.errorCode}`;
      setError(errorMessage);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          maxWidth: '500px',
          margin: '20px auto 0',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {pkg ? 'Edit Package' : 'New Package'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="URL"
            value={url}
            onChange={(e) => setURL(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Hash"
            value={hash}
            onChange={(e) => setHash(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onlyCheckExists}
                onChange={(e) => setOnlyCheckExists(e.target.checked)}
                name="onlyCheckExists"
                color="primary"
              />
            }
            label="Only Check Exists"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={mustNotExist}
                onChange={(e) => setMustNotExist(e.target.checked)}
                name="mustNotExist"
                color="primary"
              />
            }
            label="Must Not Exist"
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Box display="flex" alignItems="center" gap="20px">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default PackageForm;

