import React from 'react';
import {
  Typography,
  Container,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

const WebSocketLogDialog = ({ open, onClose, logs, closeEnabled }) => (
  <Dialog open={open} onClose={closeEnabled ? onClose : null} maxWidth="lg" fullWidth sx={{ '& .MuiDialog-paper': { width: '80%', height: '80%' } }}>
    <DialogTitle>Logs</DialogTitle>
    <DialogContent dividers sx={{ overflowY: 'auto' }}>
      <Typography component="pre" variant="body2" sx={{ fontSize: '0.8rem' }}>
        {logs.join('\n')}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Container>
        <Box display="flex"
          alignItems="center"
          justifyContent="center"
          gap="20px"
          flexDirection="row">
          <Button variant="contained" onClick={onClose} disabled={!closeEnabled}>Close</Button>
        </Box>
      </Container>
    </DialogActions>
  </Dialog>
);

export default WebSocketLogDialog;

