import React, { useState, useEffect, useCallback } from 'react';
import { Button, Box } from '@mui/material';
import axios from 'axios';
import EntityList from './components/EntityList';
import ProjectForm from './components/ProjectForm';
import FileForm from './components/FileForm';
import FileCheckForm from './components/FileCheckForm';
import FileStringForm from './components/FileStringForm';
import SettingForm from './components/SettingForm';
import PackageForm from './components/PackageForm';
import Login from './components/Login';
import Profile from './components/Profile';
import Manager from './components/Manager'; // Import the Manager component
import config from './config';

const App = () => {
  const [projects, setProjects] = useState([]);
  const [files, setFiles] = useState([]);
  const [fileChecks, setFileChecks] = useState([]);
  const [fileStrings, setFileStrings] = useState([]);
  const [packages, setPackages] = useState([]);
  const [settings, setSettings] = useState([]);
  const [currentEntity, setCurrentEntity] = useState(null);
  const [entityType, setEntityType] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth, setAuth] = useState({ username: '', password: '' });
  const [showProfile, setShowProfile] = useState(false);
  const [showManager, setShowManager] = useState(false);

  const fetchProjects = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/projects`, {
        auth: auth,
      });
      setProjects(response.data.list);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  }, [auth]);

  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/files`, {
        auth: auth,
      });
      setFiles(response.data.list);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, [auth]);

  const fetchFileChecks = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/fileChecks`, {
        auth: auth,
      });
      setFileChecks(response.data.list);
    } catch (error) {
      console.error('Error fetching file checks:', error);
    }
  }, [auth]);

  const fetchFileStrings = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/fileStrings`, {
        auth: auth,
      });
      setFileStrings(response.data.list);
    } catch (error) {
      console.error('Error fetching file checks:', error);
    }
  }, [auth]);

  const fetchPackages = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/packages`, {
        auth: auth,
      });
      setPackages(response.data.list);
    } catch (error) {
      console.error('Error fetching packages:', error);
    }
  }, [auth]);

  const fetchSettings = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/settings`, {
        auth: auth,
      });
      setSettings(response.data.list);
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  }, [auth]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchProjects();
      fetchFiles();
      fetchFileChecks();
      fetchFileStrings();
      fetchPackages();
      fetchSettings();
    }
  }, [isAuthenticated, fetchProjects, fetchFiles, fetchFileChecks, fetchFileStrings, fetchSettings, fetchPackages]);

  const handleEdit = (type, entity) => {
    setCurrentEntity(entity);
    setEntityType(type);
    setShowForm(true);
  };

  const fetchAll = () => {
    fetchProjects();
    fetchFiles();
    fetchFileChecks();
    fetchFileStrings();
    fetchPackages();
    fetchSettings();
  };

  const handleDelete = async (type, id) => {
    try {
      await axios.delete(`${config.API_BASE_URL}/${type}/${id}`, {
        auth: auth,
      });
      fetchAll();
    } catch (error) {
      console.error(`Error deleting ${type}:`, error);
    }
  };

  const handleNew = (type) => {
    setCurrentEntity(null);
    setEntityType(type);
    setShowForm(true);
  };

  const handleSave = () => {
    setShowForm(false);
    fetchAll();
  };

  const handleCancel = () => {
    setShowForm(false);
  };

  const handleLogin = async (username, password) => {
    try {
      await axios.get(`${config.API_BASE_URL}/projects`, {
        auth: { username, password },
      });
      setAuth({ username, password });
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  return (
    <div>
      {isAuthenticated && !showForm && (showProfile || showManager) && (
        <Box
          style={{ backgroundColor: '#f0f8ff', padding: '10px', marginTop: '10px' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="20px"
          mb={2}
        >
          <Button variant="contained" onClick={() => { setShowManager(false); setShowProfile(false); } }>
            Back to Entities
          </Button>
        </Box>
      )}
      {isAuthenticated && !showForm && !showProfile && !showManager && (
        <Box
          style={{ backgroundColor: '#f0f8ff', padding: '10px', marginTop: '10px' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="20px"
          mb={2}
        >
          <Button variant="contained" onClick={() => setShowProfile(true)}>
            Profile
          </Button>
          <Button variant="contained" onClick={() => setShowManager(true)}>
            Manager
          </Button>
        </Box>
      )}
      {isAuthenticated ? (
        showProfile ? (
          <Profile auth={auth} />
        ) : showForm ? (
          entityType === 'project' ? (
            <ProjectForm
              project={currentEntity}
              onSave={handleSave}
              onCancel={handleCancel}
              auth={auth}
            />
          ) : entityType === 'file' ? (
            <FileForm
              file={currentEntity}
              onSave={handleSave}
              onCancel={handleCancel}
              auth={auth}
            />
          ) : entityType === 'fileCheck' ? (
            <FileCheckForm
              file={currentEntity}
              onSave={handleSave}
              onCancel={handleCancel}
              auth={auth}
            />
          ) : entityType === 'fileString' ? (
            <FileStringForm
              file={currentEntity}
              onSave={handleSave}
              onCancel={handleCancel}
              auth={auth}
            />
          ) : entityType === 'package' ? (
            <PackageForm
              pkg={currentEntity}
              onSave={handleSave}
              onCancel={handleCancel}
              auth={auth}
            />
          ) : (
            <SettingForm
              setting={currentEntity}
              onSave={handleSave}
              onCancel={handleCancel}
              auth={auth}
            />
          )
        ) : showManager ? (
          <Manager auth={auth} />
        ) : (
          <EntityList
            projects={projects}
            files={files}
            fileChecks={fileChecks}
            fileStrings={fileStrings}
            packages={packages}
            settings={settings}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onNew={handleNew}
          />
        )
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;

