import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import { Button, List, ListItem, ListItemText } from '@mui/material';

const Profile = ({ auth }) => {
  const [projects, setProjects] = useState([]); // Initialize projects as an empty array
  const fetchProjects = useCallback(async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/profile/projects`, {
        auth: auth,
      });
      setProjects(response.data.list);
    } catch (error) {
      console.error('Error fetching profile projects:', error);
    }
  }, [auth]);
  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);
  const toggleProject = async (projectId, enabled) => {
    try {
      const url = `${config.API_BASE_URL}/profile/project/${projectId}/${enabled ? 'disable' : 'enable'}`;
      await axios.patch(url, {}, { auth: auth });
      fetchProjects();
    } catch (error) {
      console.error(`Error toggling project ${projectId}:`, error);
    }
  };
  return (
    <div>
      <h2>User Profile</h2>
      <List>
        {projects.map((project) => (
          <ListItem key={project.id}>
            <ListItemText primary={project.name} />
            <Button onClick={() => toggleProject(project.id, project.enabled)}>
              {project.enabled ? 'Disable' : 'Enable'}
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default Profile;
