import { useState, useRef } from 'react';
import axios from 'axios';
import config from '../config';

const useWebSocketLog = (auth) => {
  const [logs, setLogs] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [closeEnabled, setCloseEnabled] = useState(false);
  const wsRef = useRef(null);

  const connectWebSocket = async (requestUrl, requestType, requestData = null) => {
    setDialogOpen(true);
    setLogs(['Connecting to WebSocket...']);
    setCloseEnabled(false);

    try {
      const wsUrl = config.WS_URL.startsWith('/')
        ? `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}${config.WS_URL}`
        : config.WS_URL;

      const authCredentials = btoa(`${auth.username}:${auth.password}`);
      const ws = new WebSocket(wsUrl, authCredentials);
      wsRef.current = ws;

      ws.onopen = () => {
        // Authentication is handled in the protocol field, no need to send auth message
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (message.type === 'login') {
          axios({
            method: requestType,
            url: requestUrl,
            headers: {
              'Authorization': `Basic ${authCredentials}`
            },
            data: requestData
          }).catch(error => {
            console.error('Error updating manager data:', error);
            setLogs(logs => [...logs, `Error: ${error.message}`]);
            setCloseEnabled(true);
          });
        } else if (message.type === 'log') {
          setLogs(logs => [...logs, message.text]);
        } else if (message.type === 'taskDone') {
          setLogs(logs => [...logs, 'Task done.']);
          setCloseEnabled(true);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        setLogs(logs => [...logs, `WebSocket error: ${error.message}`]);
        setCloseEnabled(true);
      };

      ws.onclose = () => {
        setLogs(logs => [...logs, 'WebSocket connection closed.']);
        setCloseEnabled(true);
      };
    } catch (error) {
      console.error('Error setting up WebSocket:', error);
      setLogs(logs => [...logs, `Error: ${error.message}`]);
      setCloseEnabled(true);
    }
  };

  const closeWebSocket = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    setDialogOpen(false);
  };

  return {
    logs,
    dialogOpen,
    closeEnabled,
    connectWebSocket,
    closeWebSocket,
  };
};

export default useWebSocketLog;

