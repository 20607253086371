import React, { useState, useEffect } from 'react';
import {
  Typography,
  CssBaseline,
  Container,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import axios from 'axios';
import config from '../config';
import WebSocketLogDialog from './WebSocketLogDialog';
import useWebSocketLog from './useWebSocketLog';

const Manager = ({ auth }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [editProjectIndex, setEditProjectIndex] = useState(null);
  const [internalData, setInternalData] = useState(null);
  const [isApplyAndCommitEnabled, setIsApplyAndCommitEnabled] = useState(false);

  const {
    logs,
    dialogOpen,
    closeEnabled,
    connectWebSocket,
    closeWebSocket
  } = useWebSocketLog(auth);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/manager`, { auth });
      setData(response.data);
      setInternalData(JSON.parse(JSON.stringify(response.data))); // Deep copy for internal data
      setLoading(false);
    } catch (error) {
      console.error('Error fetching manager data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [auth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const maxParamLength = Math.max(...data.files.flatMap(file => file.list.map(row => row.param.length)));
  const paramColumnWidth = `${maxParamLength * 8}px`;

  const getCellStyle = (value, def) => ({
    backgroundColor: value === def ? '#d4edda' : '#f8d7da',
    position: 'relative',
    padding: '4px 8px', // Adjust the padding to reduce row height
  });

  const handleRefreshAll = async () => {
    connectWebSocket(`${config.API_BASE_URL}/manager/update`, 'POST');
  };

  const handleRefreshProject = async (projectId) => {
    connectWebSocket(`${config.API_BASE_URL}/manager/${projectId}/update`, 'POST');
  };

  const handleCloseWebSocket = () => {
    closeWebSocket();
    fetchData();
  };

  const handleEditClick = (row, projectIndex) => {
    setEditRow({ ...row }); // clone row object
    setEditProjectIndex(projectIndex);
    setEditDialogOpen(true);
  };

  const handleRevertClick = (row, projectIndex) => {
    const updatedData = { ...internalData };
    updatedData.files.forEach(file => {
      file.list.forEach(item => {
        if (item.id === row.id) {
          item.values[projectIndex].v = item.values[projectIndex].def;
          item.values[projectIndex].hash = item.values[projectIndex].hashdef;
        }
      });
    });
    setInternalData(updatedData);
    setIsApplyAndCommitEnabled(true);
  };

  const handleSetDefaultValues = (projectIndex) => {
    const updatedData = { ...internalData };
    updatedData.files.forEach(file => {
      file.list.forEach(item => {
        item.values[projectIndex].v = item.values[projectIndex].def;
        item.values[projectIndex].hash = item.values[projectIndex].hashdef;
      });
    });
    setInternalData(updatedData);
    setIsApplyAndCommitEnabled(true); // Enable the commit button
  };

  const handleDialogSubmit = () => {
    const updatedData = { ...internalData };
    updatedData.files.forEach(file => {
      file.list.forEach(item => {
        if (item.id === editRow.id) {
          item.values[editProjectIndex].v = editRow.values[editProjectIndex].v;
          item.values[editProjectIndex].hash = editRow.values[editProjectIndex].hash;
        }
      });
    });
    setInternalData(updatedData);
    setEditDialogOpen(false);
    setIsApplyAndCommitEnabled(true);
  };

  const handleDialogCancel = () => {
    setEditDialogOpen(false);
  };

  const handleValueChange = (e) => {
    const updatedRow = { ...editRow };
    updatedRow.values[editProjectIndex].v = e.target.value;
    setEditRow(updatedRow);
  };

  const handleHashChange = (e) => {
    const updatedRow = { ...editRow };
    updatedRow.values[editProjectIndex].hash = e.target.value;
    setEditRow(updatedRow);
  };

  const handleApplyAndCommit = (projectId, projectIndex) => {
    // Prepare the data to be sent
    const settings = internalData.files.flatMap(file =>
      file.list.map(row => ({
        id: row.id,
        val: row.values[projectIndex].v,
        hash: row.values[projectIndex].hash,
      }))
    );

    // Open the WebSocket log dialog and send the request data
    connectWebSocket(`${config.API_BASE_URL}/manager/${projectId}/commit`, 'PATCH', { list: settings });
  };

  return (
    <Container maxWidth={false} disableGutters>
      <CssBaseline />

          <Table sx={{ height: 'calc(100vh - 90px)', overflowY: 'auto', padding: '10px 0' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{
                  width: paramColumnWidth,
                  padding: '4px 8px',
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                  zIndex: 2,
                  fontWeight: 'bold'
                }}>
                  Default Settings
                  <br></br><Button variant="contained" color="primary" onClick={handleRefreshAll}>
                    Refresh All
                  </Button>
                </TableCell>
                {data.projects.map((project) => (
                  <TableCell key={project.id} align="center" style={{ padding: '4px 8px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 2 }}>
                    {project.name}
                    <br></br><Button variant="contained" onClick={() => handleRefreshProject(project.id)}>
                      Refresh
                    </Button>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell style={{
                  width: paramColumnWidth,
                  padding: '4px 8px',
                  position: 'sticky',
                  left: 0,
                  backgroundColor: '#e9ecef',
                  fontWeight: 'bold',
                  zIndex: 1,
                }}>
                  File Checks
                </TableCell>
              </TableRow>
              {data.fileChecks.map((fileCheck) => (
                <TableRow key={fileCheck.id}>
                  <TableCell style={{
                    width: paramColumnWidth,
                    backgroundColor: '#fff',
                    padding: '4px 8px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1
                  }}>
                    {fileCheck.name}
                  </TableCell>
                  {fileCheck.list.map((status, index) => (
                    <TableCell key={index} align="center" style={{
                      backgroundColor: status ? '#d4edda' : '#f8d7da',
                      color: status ? '#155724' : '#721c24',
                      padding: '4px 8px'
                    }}>
                      {status ? 'OK' : 'NO'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

              <TableRow>
                <TableCell style={{
                  width: paramColumnWidth,
                  padding: '4px 8px',
                  position: 'sticky',
                  left: 0,
                  backgroundColor: '#e9ecef',
                  fontWeight: 'bold',
                  zIndex: 1,
                }}>
                  File Strings
                </TableCell>
              </TableRow>
              {data.fileStrings.map((fileString) => (
                <TableRow key={fileString.id}>
                  <TableCell style={{
                    width: paramColumnWidth,
                    backgroundColor: '#fff',
                    padding: '4px 8px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1
                  }}>
                    {fileString.name}
                  </TableCell>
                  {fileString.list.map((status, index) => (
                    <TableCell key={index} align="center" style={{
                      backgroundColor: status ? '#d4edda' : '#f8d7da',
                      color: status ? '#155724' : '#721c24',
                      padding: '4px 8px'
                    }}>
                      {status ? 'OK' : 'NO'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

              <TableRow>
                <TableCell style={{
                  width: paramColumnWidth,
                  padding: '4px 8px',
                  position: 'sticky',
                  left: 0,
                  backgroundColor: '#e9ecef',
                  fontWeight: 'bold',
                  zIndex: 1,
                }}>
                  Packages
                </TableCell>
              </TableRow>
              {data.packages.map((pkg) => (
                <TableRow key={pkg.id}>
                  <TableCell style={{
                    width: paramColumnWidth,
                    backgroundColor: '#fff',
                    padding: '4px 8px',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1
                  }}>
                    {pkg.name}
                  </TableCell>
                  {pkg.list.map((status, index) => (
                    <TableCell key={index} align="center" style={{
                      backgroundColor: status ? '#d4edda' : '#f8d7da',
                      color: status ? '#155724' : '#721c24',
                      padding: '4px 8px'
                    }}>
                      {status}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

              {internalData.files.map((file) => (
                <React.Fragment key={file.id}>
                  <TableRow>
                    <TableCell style={{
                      backgroundColor: '#e9ecef',
                      fontWeight: 'bold',
                      padding: '4px 8px',
                      position: 'sticky',
                      left: 0,
                      zIndex: 1
                    }}>
                      {file.name}
                    </TableCell>
                  </TableRow>
                  {file.list.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell style={{
                        width: paramColumnWidth,
                        padding: '4px 8px',
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#fff',
                        zIndex: 1
                      }}>{row.param}</TableCell>
                      {internalData.projects.map((project, index) => {
                        const valueData = row.values[index];
                        const value = valueData ? valueData.v : '';
                        const def = valueData ? valueData.def : '';
                        const hash = valueData ? valueData.hash : '';
                        const hashDef = valueData ? valueData.hashdef : '';

                        return (
                          <TableCell key={project.id} style={{ ...getCellStyle(value, def), textAlign: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <div style={{ flexGrow: 1, textAlign: 'center' }}>
                                <div>{value}</div>
                                {value !== def && <div style={{ color: '#6c757d' }}>{def}</div>}
                                <div style={{ color: '#6c757d' }}>{hash}</div>
                              </div>
                              <div style={{ display: 'flex' }}>
                                <IconButton onClick={() => handleEditClick(row, index)}><EditIcon /></IconButton>
                                {value !== def && <IconButton onClick={() => handleRevertClick(row, index)}><RestoreIcon /></IconButton>}
                              </div>
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
              <TableRow>
                <TableCell style={{ padding: '4px 8px', width: paramColumnWidth }}></TableCell>
                {internalData.projects.map((project, index) => (
                  <TableCell key={project.id} align="center" style={{ padding: '4px 8px' }}>
                    <Box display="flex" flexDirection="column" alignItems="center" sx={{ mx: 2 }}>
                      <Button variant="contained" color="primary" onClick={() => handleSetDefaultValues(index)} sx={{ mb: 1 }}>
                        Set Default Values
                      </Button>
                      <Button variant="contained" color="secondary" onClick={() => handleApplyAndCommit(project.id, index)} disabled={!isApplyAndCommitEnabled}>
                        Apply and Commit
                      </Button>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>

      <WebSocketLogDialog open={dialogOpen} onClose={handleCloseWebSocket} logs={logs} closeEnabled={closeEnabled} />
      <Dialog open={editDialogOpen} onClose={handleDialogCancel} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Value</DialogTitle>
        <DialogContent style={{ paddingBottom: '0px', marginBottom: '0px' }}>
          <TextField
            margin="dense"
            label="Value"
            fullWidth
            value={editRow ? editRow.values[editProjectIndex].v : ''}
            onChange={handleValueChange}
          />
          <TextField
            margin="dense"
            label="Hash"
            fullWidth
            value={editRow ? editRow.values[editProjectIndex].hash : ''}
            onChange={handleHashChange}
          />
          <TextField
            margin="dense"
            label="Default Value"
            fullWidth
            value={editRow ? editRow.values[editProjectIndex].def : ''}
            InputProps={{
              readOnly: true,
              style: {
                cursor: 'not-allowed',
                userSelect: 'none',
              },
              inputProps: {
                style: {
                  cursor: 'not-allowed',
                  userSelect: 'none',
                }
              }
            }}
          />
          <TextField
            margin="dense"
            label="Default Hash"
            fullWidth
            value={editRow ? editRow.values[editProjectIndex].hashdef : ''}
            InputProps={{
              readOnly: true,
              style: {
                cursor: 'not-allowed',
                userSelect: 'none',
              },
              inputProps: {
                style: {
                  cursor: 'not-allowed',
                  userSelect: 'none',
                }
              }
            }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center', gap: '20px' }}>
          <Button onClick={handleDialogCancel} variant="contained" color="secondary">Cancel</Button>
          <Button onClick={handleDialogSubmit} variant="contained" color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Manager;

