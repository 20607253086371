import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import axios from 'axios';
import config from '../config';

const ProjectForm = ({ project, onSave, onCancel, auth }) => {
  const [url, setUrl] = useState(project ? project.url : '');
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [stringID, setStringID] = useState(project ? project.stringID : ''); // Add stringID state
  const [discordURL, setDiscordURL] = useState(project ? project.discordURL : ''); // Add discordURL state
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { url, login, password, stringID, discordURL }; // Include discordURL in the data object
      if (project) {
        await axios.put(`${config.API_BASE_URL}/project/${project.id}`, data, {
          auth: auth,
        });
      } else {
        await axios.post(`${config.API_BASE_URL}/project`, data, {
          auth: auth,
        });
      }
      onSave();
    } catch (error) {
      const errorMessage = `HTTP Status: ${error.response.status}, Error Code: ${error.response.data.errorCode}`;
      setError(errorMessage);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          maxWidth: '500px',
          margin: '20px auto 0',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {project ? 'Edit Project' : 'New Project'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="String ID"
            value={stringID}
            onChange={(e) => setStringID(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Discord URL"
            value={discordURL}
            onChange={(e) => setDiscordURL(e.target.value)}
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Box display="flex" alignItems="center" gap="20px">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </form>
        <p sx={{ textAlign: 'center' }}>URL examples: svn://svn.snipe.dev:1234/InstantRacingMobile/InstantRacing/<br></br>https://github_pat_TOKEN@github.com/Mini-IT/project-color-block/tree/main/ColorBlock/</p>
      </Box>
    </Container>
  );
};

export default ProjectForm;

